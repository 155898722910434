import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { OverlappingRectBorder } from '../stories/OverlappingRectBorder';
import {Timeline, Events, ImageEvent, createTheme, themes} from '@merc/react-timeline';

const valle = new URL('./assets/images/valle.jpg?as=webp&width=960px', import.meta.url);
const funkysoul = new URL('./assets/images/funkysoul.jpg?as=webp&width=600px', import.meta.url);
const resolvingproblems = new URL('./assets/images/resolvingproblems.jpg?as=webp&width=600px', import.meta.url);
const barcelona = new URL('./assets/images/barcelona.jpeg?as=webp&width=600px', import.meta.url);
const goldroom = new URL('./assets/images/thegoldroom.jpg?as=webp&with=600px', import.meta.url);
const zion = new URL('./assets/images/zion.jpeg?as=webp&with=600px', import.meta.url);
const graduation = new URL('./assets/images/graduation.jpeg?as=webp&with=600px', import.meta.url);
const holoday = new URL('./assets/images/holiday.jpg?as=webp&width=600px', import.meta.url);
const home = new URL('./assets/images/home.jpg?as=webp&width=600px', import.meta.url);
const order = new URL('./assets/images/order.jpg?as=webp&width=600px', import.meta.url);
const engagement = new URL('./assets/images/engagement.jpeg?as=webp&width=600px', import.meta.url);

function Header() {
  return (
    <React.Fragment>
      <div className="legrand" style={{ fontSize: '3rem', lineHeight: '3rem', textAlign: 'center' }}>
        <p>Melissa Garcia</p>
        <p className="sidelined" style={{ fontSize: '1.5rem', lineHeight: '1.5rem' }}>and</p>
        <p>Zachary Babtkis</p>
      </div>

      <nav style={{ margin: '8px 0px', fontSize: '1.5rem', display: 'flex', justifyContent: 'space-between' }}>
        <Link className="menu-item" to="/">Home</Link>
        <Link className="menu-item" to="/story">Story</Link>
        <Link className="menu-item" to="/qa">Q&A</Link>
        <Link className="menu-item" to="/dinner">Dinner</Link>
      </nav>
    </React.Fragment>
  );
}

function HomePage() {
  return (
    <React.Fragment>
      <Header />
      <div style={{ marginBottom: '48px', marginTop: '24px' }}>
        <img src={ valle } width="100%" height="auto" />
      </div>
      <OverlappingRectBorder color="#80070e" size={ 3 }>
        <div style={{ textAlign: 'center', lineHeight: '2rem', fontSize: '1.5rem', padding: '60px' }}>
          <div>
            <p style={{ fontSize: '1.5rem' }}>Saturday, April 23rd, 2022</p>
            <p style={{ fontSize: '1rem' }}>at 5 o'clock in the evening</p>
          </div>
          <div style={{ marginTop: '32px' }}>
            <p style={{ fontSize: '1.5rem' }}>The Oviatt Penthouse</p>
            <p style={{ fontSize: '1rem' }}>617 South Olive Street</p>
            <p style={{ fontSize: '1rem' }}>Los Angeles</p>
          </div>
        </div>

      </OverlappingRectBorder>
      <p style={{ lineHeight: '1.75rem', marginTop: '48px', textAlign: 'justify', fontSize: '1.25rem' }}>
        Join us on the rooftop of the Oviatt building in Downtown LA. Shortly after guests arrive, there will be a brief ceremony establishing the marriage of Melissa and Zack. Enjoy a cocktail and photo hour before we sit down for dinner. After dinner, guests are welcome to take photos, tour the historic space, dance, and enjoy the open bar.
      </p>
    </React.Fragment>
  );
}

const timelineTheme = createTheme(themes.default, {
  timeline: {
    fontFamily: 'Della Respira, sans-serif',
  },
  timelineTrack: {
    backgroundColor: '#80070e',
    width: '4px',
  },
  date: {
    backgroundColor: '#80070e',
    fontSize: '20px',
    color: '#ffffff',
  },
  marker: {
    border: '4px solid #80070e',
    backgroundColor: '#F6EDEB',
    width: '24px',
    height: '24px',
    transform: 'translateX(12px)'
  },
  imageText: {
    fontSize: '16px',
  },
  card: {
    backgroundColor: 'none',
    boxShadow: 'none',
    color: '#80070e'
  }
});

function StoryPage() {
  return (
    <React.Fragment>
      <Header />
      <Timeline theme={ timelineTheme }>
        <Events>
          <ImageEvent date="September 17th, 2015" src={ funkysoul } text="Third date, Funky Soul" />
          <ImageEvent date="October 8th, 2016" src={ resolvingproblems } text="Resolving problems" />
          <ImageEvent date="November 17th, 2017" src={ barcelona } text="Barcelona" />
          <ImageEvent date="December 31st, 2017" src={ goldroom } text="NYE @ Gold Room" />
          <ImageEvent date="January 14th, 2018" src={ zion } text="Zion" />
          <ImageEvent date="June 2nd, 2018" src={ graduation } text="Graduate Graduation" />
          <ImageEvent date="December 14th, 2019" src={ holoday } text="Holiday Party" />
          <ImageEvent date="October 8th, 2020" src={ home } text="Our Home" />
          <ImageEvent date="November 25th, 2021" src={ order } text="That's an order" />
          <ImageEvent date="January 8th, 2022" src={ engagement } text="" />
        </Events>
      </Timeline>
    </React.Fragment>
  );
}

function QAPage() {
  return (
    <React.Fragment>
      <Header />

      <div>
        <div className="qa-question">
          <h5>WHAT IS THE RECOMMENDED ATTIRE?</h5>
          <p>Semi-formal</p>
        </div>

        <div className="qa-question">
          <h5>HOW DO I GET TO THE PENTHOUSE?</h5>
          <p>Enter through the front gates of the Oviatt and check in with security by the elevators immediately to the left.</p>
        </div>

        <div className="qa-question">
          <h5>WILL EVERYONE IN ATTENDANCE BE VACCINATED?</h5>
          <p>
            We encourage everyone who isn't vaccinated to get the first two doses of the vaccine before the wedding.
            We also ask that everyone, including those vaccinated take a rapid antigen test the day of the event.</p>
        </div>

        <div className="qa-question">
          <h5>WHERE CAN I PARK?</h5>
          <p>Valet parking will be available at the front door for $17. Garage and lot parking can be found within a block of the venue for around $10.</p>
        </div>

        <div className="qa-question">
          <h5>WILL THERE BE A VEGAN OR VEGETARIAN OPTION AT DINNER?</h5>
          <p>Yes! Check out the Menu tab for VGN options.</p>
        </div>

        <div className="qa-question">
          <h5>WHERE CAN I FIND HISTORY ON THE VENUE?</h5>
          <p>You can read a brief history on <a href="https://en.wikipedia.org/wiki/James_Oviatt_Building" target="_blank">Wikipedia</a></p>
        </div>

        <div className="qa-question">
          <h5>MAY I BRING A GIFT?</h5>
          <p>There will be a table for small gifts at the entrance of the venue. We do not have a registry, and you do not need to bring a gift!</p>
        </div>

        <div className="qa-question">
          <h5>IS THERE A METRO STATION NEARBY?</h5>
          <p>The Oviatt is two blocks from the Pershing Square train station.</p>
        </div>

        <div className="qa-question">
          <h5>WHERE CAN I SLEEP?</h5>
          <p>We didn't book a block of rooms, but you can find a room at one of the nearby hotels. We recommend either the Millennium Biltmore, or the Los Angeles Athletic Club, which are each within a block of the venue.</p>
        </div>

        <div className="qa-question">
          <h5>WHAT TIME DOES THE EVENT END?</h5>
          <p>We will say our farewells at 10 PM.</p>
        </div>

        <div className="qa-question">
          <h5>WILL THERE BE AN AFTER PARTY?</h5>
          <p>Of course. We'll walk to La Cita after the scheduled end of the wedding.</p>
        </div>
      </div>
    </React.Fragment>
  )
}

function RSVPPage() {
  return (
    <React.Fragment>
      <Header />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfJDjnaVtbE_5brCusJQbywDO7Hq2VtfcAjjBWedBOuwZyCYQ/viewform?embedded=true"
          width="640" height="800" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
        </iframe>
      </div>
    </React.Fragment>
  );
}

function Dinner() {
  return (
    <React.Fragment>
      <Header />
      <div style={{ lineHeight: 'initial', textAlign: 'center' }}>
        <h2 style={{ textDecoration: 'underline' }}>Seating</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))', gridGap: '32px', justifyItems: 'center' }}>
          <div>
            <strong className="table-header">Table 1</strong>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>Randall B.</li>
              <li>Carolyn C.</li>
              <li>Dorothy C.</li>
              <li>Alan B.</li>
              <li>Myrna B.</li>
              <li>David L.</li>
              <li>David B.</li>
              <li>Elyse B.</li>
              <li>Chuck A.</li>
            </ul>
          </div>
          <div>
            <strong className="table-header">Table 2</strong>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>Carmen R.</li>
              <li>Erasmo R.</li>
              <li>Sergio M.</li>
              <li>Azniv M.</li>
              <li>Laura G.</li>
              <li>Alex G.</li>
              <li>Ricardo C.</li>
              <li>Josue G.</li>
              <li>Sarah Z.</li>
            </ul>
          </div>
          <div>
            <strong className="table-header">Table 3</strong>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>Lilian R.</li>
              <li>Mark B.</li>
              <li>Delmy D.</li>
              <li>Andrew P.</li>
              <li>Michelle O.</li>
              <li>Ejike N.</li>
              <li>Megane R.</li>
              <li>Thiago M.</li>
            </ul>
          </div>
          <div>
            <strong className="table-header">Table 4</strong>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>Asa P.</li>
              <li>Monica A.</li>
              <li>Ben B.</li>
              <li>Kylee O.</li>
              <li>Brady K.</li>
              <li>Enddy D.</li>
              <li>Cherry M.</li>
              <li>Baby Nyla</li>
              <li>Callie B.</li>
              <li>Stanley S.</li>
            </ul>
          </div>
          <div>
            <strong className="table-header">Table 5</strong>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>Michael S.</li>
              <li>Christine C.</li>
              <li>Derrick S.</li>
              <li>Giulia</li>
              <li>Paul T.</li>
              <li>Jazmine T.</li>
              <li>Baby Jade</li>
              <li>Chris R.</li>
              <li>Tyler W.</li>
              <li>Carlos C.</li>
              <li></li>
            </ul>
          </div>
          <div>
            <strong className="table-header">Table 6</strong>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>Karl V.</li>
              <li>Flamenco Souls</li>
            </ul>
          </div>
        </div>
        <h2 style={{ textDecoration: 'underline' }}>Menu</h2>
        <h3 className="menu-header sidelined">Appetizers (6 - 7 PM)</h3>
        <ul className="dinner-menu">
          <li>
            <b>Kumamoto Oyster with Sesame Dressing</b>
            <i>Pickled Peppers (GF, NF)</i>
          </li>
          <li>
            <strong>Fire Roasted Baby Zucchini & Baba Ghanoush</strong>
            <i>Pistachio, Kalamata Olives (VGN, GF)</i>
          </li>
          <li>
            <strong>Vegetable Gyoza</strong>
            <i>Ginger Plum Sauce (VGN, NF)</i>
          </li>
          <li>
            <strong>Tangy Chicken Sate</strong>
            <i>Peanut Sauce, Crispy Garlic, Chives </i>
          </li>
          <li>
            <strong>Crispy Mini Braised Short Rib Taco</strong>
            <i>Salsa, Avocado, Queso Fresco (GF)</i>
          </li>
        </ul>
        <h3 className="menu-header sidelined">Dinner Station (7 - 8 PM)</h3>
        <ul className="dinner-menu">
          <li>
            <strong>Roasted Local Sea Bass</strong>
            <i>Slow Roasted Tomato Basil, Lemon (DF, GF, NF)</i>
          </li>
          <li>
            <strong>Roasted Fingerling Potato</strong>
            <i>Thyme Oil, Rosemary, Parsley, Garlic (VGN, DF, NF)</i>
          </li>
          <li>
            <strong>Harissa Braised Lamb Shank</strong>
            <i>San Marzano Tomatoes (NF, DF)</i>
          </li>
          <li>
            <strong>Indian Spiced Vegetable Tagine</strong>
            <i>Couscous, Golden Raisins, Almonds, Herb Salad (VGN)</i>
          </li>
          <li>
            <strong>Roasted Cauliflower Salad</strong>
            <i>Watercress, Roasted Almonds, Golden Raisins, Curry Vinaigrette (VGN, GF)</i>
          </li>
          <br />
          <li>
            <strong>Kids Menu: Grilled Chicken Breast Strips</strong>
            <i>Buttered Rice, Carrots and Broccoli</i>
          </li>
        </ul>
        <h3 className="menu-header sidelined">Cake (8:30 - 9:30 PM)</h3>
        <ul className="dinner-menu">
          <li>
            <strong>Chocolate Cake</strong>
            <i>Salted Caramel Filling</i>
          </li>
          <li>
            <strong>Passion Fruit Cake</strong>
            <i>Passion Fruit Filling</i>
          </li>
          <li><strong>Coffee</strong></li>
          <li><strong>Tea</strong></li>
        </ul>
      </div>
      <h3>Legend</h3>
      <legend style={{ lineHeight: 'initial' }}>
        <li>GF: Gluten Free</li>
        <li>DF: Dairy Free</li>
        <li>NF: Dairy Free</li>
        <li>VGN: Vegan and Vegetarian</li>
      </legend>
    </React.Fragment>
  )
}

function Website() {
  return (
    <div
      style={{
        backgroundColor: 'white',
        color: '#80070e',
        padding: '48px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '12px'
    }}>
      <div style={{ maxWidth: '720px', width: '100%', lineHeight: '1px' }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/story" element={<StoryPage />} />
            <Route path="/qa" element={<QAPage />} />
            <Route path="/dinner" element={<Dinner />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
}

ReactDOM.render(<Website />, document.getElementById('app-root'));
