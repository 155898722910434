import React, { useCallback } from 'react';
import C2S from 'canvas2svg';

function renderCornerBox(ctx, config) {
  const {
    boxHeight,
    boxWidth,
    boxOffset,
    topBoxNotchWidth,
    bottomBoxNotchWidth,
    boxDisconnectWidth,
    boxDisconnectHeight,
  } = config;

  ctx.beginPath();
  ctx.moveTo(boxOffset + boxWidth + topBoxNotchWidth, boxOffset);
  ctx.lineTo(boxOffset + boxWidth + topBoxNotchWidth, boxOffset + boxHeight - boxDisconnectHeight);
  ctx.lineTo(boxOffset + boxWidth, boxOffset + boxHeight - boxDisconnectHeight);
  ctx.lineTo(boxOffset + boxWidth, boxOffset);
  ctx.lineTo(boxOffset, boxOffset);
  ctx.lineTo(boxOffset, boxOffset + boxHeight);
  ctx.lineTo(boxOffset + boxWidth - boxDisconnectWidth, boxOffset + boxHeight);
  ctx.lineTo(boxOffset + boxWidth - boxDisconnectWidth, boxOffset + boxHeight + bottomBoxNotchWidth);
  ctx.lineTo(boxOffset, boxOffset + boxHeight + bottomBoxNotchWidth);
  ctx.stroke();
}

export function Corner({ direction, color }) {
  const width = 100;
  const height = 120;

  const appendSvg = useCallback(function drawCorner(el) {
    if (!el) { return; }

    const ctx = C2S(width, height);

    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.lineWidth = 4;

    el.style.position = 'absolute';

    switch (direction) {
      case 'bottom-left':
        ctx.translate(0, height);
        ctx.scale(1, -1);
        el.style.bottom = 0;
        el.style.left = 0;
        break;
      case 'bottom-right':
        ctx.translate(width, height);
        ctx.rotate(-180 * Math.PI / 180);
        el.style.bottom = 0;
        el.style.right = 0;
        break;
      case 'top-right':
        ctx.translate(width,0);
        ctx.scale(-1, 1);
        el.style.top = 0;
        el.style.right = 0;
        break;
      case 'top-left':
      default:
        el.style.top = 0;
        el.style.left = 0;
        break;
    }

    renderCornerBox(ctx, {
      boxHeight: 70,
      boxWidth: 50,
      boxOffset: 2,
      topBoxNotchWidth: 30,
      bottomBoxNotchWidth: 30,
      boxDisconnectHeight: 25,
      boxDisconnectWidth: 20,
    });

    renderCornerBox(ctx, {
      boxHeight: 70,
      boxWidth: 50,
      boxOffset: 17,
      topBoxNotchWidth: 30,
      bottomBoxNotchWidth: 30,
      boxDisconnectHeight: 30,
      boxDisconnectWidth: 20,
    });

    el.appendChild(ctx.getSvg());
  }, []);

  return (
    <div ref={ appendSvg }>
    </div>
  );
}

function Edges({ side, color }) {
  const borderStyle = { position: 'absolute', border: `2px solid ${color}` };
  const topBorderStyle = { ...borderStyle };
  const bottomBorderStyle = { ...borderStyle };

  switch (side) {
    case 'top':
      topBorderStyle.top = 0;
      topBorderStyle.left = 80;
      topBorderStyle.right = 80;

      bottomBorderStyle.top = 15;
      bottomBorderStyle.left = 95;
      bottomBorderStyle.right = 95;
      break;

    case 'right':
      topBorderStyle.top = 100;
      topBorderStyle.bottom = 100;
      topBorderStyle.right = 0;

      bottomBorderStyle.top = 115;
      bottomBorderStyle.bottom = 115;
      bottomBorderStyle.right = 15;
      break;

    case 'bottom':
      topBorderStyle.bottom = 0;
      topBorderStyle.left = 80;
      topBorderStyle.right = 80;

      bottomBorderStyle.bottom = 15;
      bottomBorderStyle.left = 95;
      bottomBorderStyle.right = 95;
      break;

    case 'left':
    default:
      topBorderStyle.top = 100;
      topBorderStyle.bottom = 100;
      topBorderStyle.left = 0;

      bottomBorderStyle.top = 115;
      bottomBorderStyle.bottom = 115;
      bottomBorderStyle.left = 15;
      break;
  }

  return (
    <React.Fragment>
      <div style={ topBorderStyle } />
      <div style={ bottomBorderStyle } />
    </React.Fragment>
  )
}

export function OverlappingRectBorder({ children, color = 'black' }) {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
        minWidth: '300px',
      }}>
      { children }
      <Corner direction="top-left" color={ color } />
      <Edges side="top" color={ color } />
      <Corner direction="top-right" color={ color } />
      <Edges side="right" color={ color } />
      <Corner direction="bottom-right" color={ color } />
      <Edges side="bottom" color={ color } />
      <Corner direction="bottom-left" color={ color } />
      <Edges side="left" color={ color } />
    </div>
  );
};
